<template>
  <div class="container">
    <v-card
      v-if="loaded"
    >
      <v-toolbar
        dark
        dense
        flat
        color="primary"
      >
        <v-toolbar-title>OLT Status</v-toolbar-title>
      </v-toolbar>
      <vue-apex-charts
        type="pie"
        :options="options"
        :series="series"
      />
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'

export default {
  name: 'OltStatusPieChartContainer',
  components: { VueApexCharts },
  data: () => ({
    loaded: false,
    options: {
      // title: {
      //   text: 'OLT Status',
      //   align: 'center',
      // },
      chart: {
        id: 'OltStatusPieChartContainer',
        animations: {
          enabled: true,
          easing: 'linear',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      labels: null,
      colors: ['#41B883', '#E46651', '#CCCCCC'],
    },
    series: null,
  }),
  mounted() {
    this.loaded = false
    this.getDataFromAPI()
    this.interval = setInterval(() => {
      this.getDataFromAPI()
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    getDataFromAPI() {
      axios.get('/dashboard/oltStatusSummary')
        .then((response) => {
          const result = response.data
          this.options.labels = result.map(a => a.status)
          this.series = result.map(a => a.count)
          this.loaded = true
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
  },
}
</script>
