<template>
  <v-container>
    <v-layout
      wrap
    >
      <v-flex
        xs12
        sm12
        md12
        lg3
        xl3
        grow
        pa-1
      >
        <olt-status-horizontal-bar-chart-container />
      </v-flex>
      <v-flex
        xs12
        sm12
        md12
        lg3
        xl3
        grow
        pa-1
      >
        <pon-status-horizontal-bar-chart-container />
      </v-flex>
      <v-flex
        xs12
        sm12
        md12
        lg3
        xl3
        grow
        pa-1
      >
        <olt-status-pie-chart-container />
      </v-flex>
      <v-flex
        xs12
        sm12
        md12
        lg3
        xl3
        grow
        pa-1
      >
        <pon-status-pie-chart-container />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import OltStatusPieChartContainer from './OltStatusPieChartContainer.vue'
import PonStatusPieChartContainer from './PonStatusPieChartContainer.vue'
import PonStatusHorizontalBarChartContainer from './PonStatusHorizontalBarChartContainer.vue'
import OltStatusHorizontalBarChartContainer from './OltStatusHorizontalBarChartContainer.vue'

export default {
  name: 'Dashboard',
  components: {
    OltStatusPieChartContainer,
    PonStatusPieChartContainer,
    PonStatusHorizontalBarChartContainer,
    OltStatusHorizontalBarChartContainer,
  },
  props: {
    // msg: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
