<template>
  <div class="container">
    <v-card
      v-if="loaded"
    >
      <v-toolbar
        dark
        dense
        flat
        color="primary"
      >
        <v-toolbar-title>PON Down (ONU >= 5)</v-toolbar-title>

        <v-spacer />

        <v-btn
          icon
          :to="'pondownlist'"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>

        <v-btn
          icon
          :to="'oltinterfaceoperstatusdownalarmlist'"
        >
          <v-icon>
            mdi-history
          </v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="hasSeries">
        <vue-apex-charts
          type="bar"
          :options="options"
          :series="series"
        />
      </div>
      <div
        v-else
        class="text-center"
      >
        <v-chip
          class="ma-2"
          color="green"
          text-color="white"
          label
          x-large
        >
          ไม่พบ PON Down
        </v-chip>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'

export default {
  name: 'PonStatusHorizontalBarChartContainer',
  components: { VueApexCharts },
  data: () => ({
    loaded: false,
    interval: null,
    hasSeries: false,
    options: {
      // title: {
      //   text: 'PON Down (มี ONU มากกว่า 10 ตัว)',
      //   align: 'center',
      // },
      chart: {
        id: 'PonStatusHorizontalBarChartContainer',
        animations: {
          enabled: true,
          easing: 'linear',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ['#E46651'],
      xaxis: {
        categories: null,
        labels: {
          rotate: 0,
          rotateAlways: false,
          trim: false,
        },
      },
    },
    series: [{
      name: 'PON Down',
      data: null,
    }],
    officeSectorNameList: null,
    ponDownCount: null,
  }),
  mounted() {
    this.loaded = false
    this.hasSeries = false
    this.getDataFromAPI()
    this.interval = setInterval(() => {
      this.getDataFromAPI()
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    getDataFromAPI() {
      axios.get('/dashboard/ponDownSummary')
        .then((response) => {
          const result = response.data
          this.options.xaxis.categories = result.map(a => a.officeSectorName)
          this.series[0].data = result.map(a => a.count)
          this.hasSeries = result.length > 0
          this.loaded = true
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
  },
}
</script>
